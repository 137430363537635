"use client";

import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./SiteHeader.module.scss";
import clsx from "clsx";
import Link from "next/link";
import { signOut } from "next-auth/react";
import { usePathname } from "next/navigation";
import { useRouter } from "next/navigation";
import {
  ChevronsLeft,
  ChevronsRight,
  Menu as Hamburger,
  Plus,
  X,
} from "lucide-react";
import { Menu, Transition } from "@headlessui/react";
import ProfileMenu from "@/components/components/ProfileMenu";
import { Tooltip } from "@/components/feedback/Tooltip";
import { useSession } from "@/providers/Session";
import { ChallengeGrid } from "@/components/structure/ChallengeGrid";

/**
 *
 * SiteHeader
 *
 */
const SiteHeader = ({ user, ...props }) => {
  const Router = useRouter();
  const pathname = usePathname();
  const [menuOpen, setMenuOpen] = useState(false);
  const Pathname = usePathname();
  const { showHistory, setShowHistory, id, challengeIsPublic } = useSession();

  const [open, setOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const navRef = useRef(null);

  const controlNavbar = useCallback(() => {
    if (typeof window !== "undefined") {
      const currentScrollY = window.scrollY;

      if (currentScrollY < lastScrollY || currentScrollY < 10) {
        setIsVisible(true);
      } else if (currentScrollY > lastScrollY && currentScrollY > 10) {
        setIsVisible(false);
      }

      setLastScrollY(currentScrollY);
    }
  }, [lastScrollY]);

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isDownSwipe = distance > 50;
    const isUpSwipe = distance < -50;
    if (isDownSwipe) {
      setIsVisible(false);
    } else if (isUpSwipe) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      let ticking = false;

      const onScroll = () => {
        if (!ticking) {
          window.requestAnimationFrame(() => {
            controlNavbar();
            ticking = false;
          });
          ticking = true;
        }
      };

      window.addEventListener("scroll", onScroll, { passive: true });
      window.addEventListener("touchstart", onTouchStart, { passive: true });
      window.addEventListener("touchmove", onTouchMove, { passive: true });
      window.addEventListener("touchend", onTouchEnd, { passive: true });

      return () => {
        window.removeEventListener("scroll", onScroll);
        window.removeEventListener("touchstart", onTouchStart);
        window.removeEventListener("touchmove", onTouchMove);
        window.removeEventListener("touchend", onTouchEnd);
      };
    }
  }, [controlNavbar]);

  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  return (
    <>
      <header
        ref={navRef}
        className={clsx(
          styles["site-header"],
          "flex justify-between items-center px-4 lg:px-8 py-4 bg-[#000] z-50 fixed top-0 left-0 w-full",
          ` ${isVisible && !menuOpen ? "" : "transform -translate-y-full"}`
        )}
        {...props}
      >
        <Link href="/">
          <img src="/logo-white.png" alt="Thinknado Logo" width={150} />
        </Link>
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="text-white menu-button flex flex-col items-center gap-1 w-[46px]"
        >
          <span className="bg-[#F06FD9] h-[9px] w-full block"></span>
          <span className="bg-[#F06FD9] h-[9px] w-[26px] block"></span>
          <span className="bg-[#F06FD9] h-[9px]  w-[16px] block"></span>
          <span className="bg-[#F06FD9] h-[9px]  w-[6px] block"></span>
        </button>
        <div className={`nav z-[50] ${menuOpen ? "open" : "close"}`}>
          <nav className="flex items-center justify-end gap-6 flex-row mb-10 md:mb-0">
            {/* <Link
              onClick={() => setMenuOpen(false)}
              className={`block md:hidden ${
                pathname === "/" ? "text-pink" : "text-white hover:text-pink"
              }`}
              href="/"
            >
              Home
            </Link> */}
            <Link
              onClick={() => setMenuOpen(false)}
              className={`${
                pathname.includes("the-app")
                  ? "text-pink"
                  : "text-white hover:text-pink"
              } transition-all duration-200 ease-in-out`}
              href="/the-app"
            >
              The App
            </Link>

            <Link
              onClick={() => setMenuOpen(false)}
              className={`${
                pathname.includes("the-toolkit")
                  ? "text-pink"
                  : "text-white hover:text-pink"
              } transition-all duration-200 ease-in-out`}
              href="/the-toolkit"
            >
              The Toolkit
            </Link>

            {/* <Link
              onClick={() => setMenuOpen(false)}
              className={`${
                pathname.includes("the-workshop")
                  ? "text-pink"
                  : "text-white hover:text-pink"
              }  transition-all duration-200 ease-in-out`}
              href="/the-workshop"
            >
              The Workshop
            </Link> */}

            <Link
              onClick={() => setMenuOpen(false)}
              className={`${
                pathname.includes("case-studies")
                  ? "text-pink"
                  : "text-white hover:text-pink"
              }  transition-all duration-200 ease-in-out`}
              href="/case-studies"
            >
              Case Studies
            </Link>

            <Link
              onClick={() => setMenuOpen(false)}
              className={`${
                pathname.includes("contact")
                  ? "text-pink"
                  : "text-white hover:text-pink"
              }  transition-all duration-200 ease-in-out`}
              href="/contact"
            >
              Contact
            </Link>

            {/* <button className="text-white" onClick={() => alert("Coming Soon!")}>
            Shop
          </button> */}
            {/* <Link
            className="text-white"
            href="https://www.thinknado.com/stuff/p/thinknado-box"
            target="_blank"
          >
            Shop
          </Link> */}
          </nav>
          {/* {!user && <span className="text-white opacity-30">|</span>} */}
          <nav className="flex items-center justify-end gap-4">
            {user ? (
              <>
                <ProfileMenu>
                  <span
                    className={`${styles.avatar} flex items-center justify-center`}
                  >
                    <img
                      src={user?.image ? user?.image : "/default-profile.png"}
                      className="aspect-square rounded-full w-14 lg:w-15"
                    />
                  </span>
                </ProfileMenu>
              </>
            ) : (
              <>
                {/* <Link href="/auth/sign-in" className="text-white">
                Sign In
              </Link> */}
                <Link
                  href="/auth/sign-in"
                  className="text-purple border-purple border-2 px-4 py-2 rounded-thinknado hover:text-white hover:bg-purple transition-all duration-200 font-medium"
                >
                  Get Started
                </Link>
              </>
            )}
          </nav>
        </div>
      </header>
      {user && (
        <>
          <div className="flex justify-between px-8 py-4 border-b border-b-black sticky bg-black text-white top-0 z-20">
            <button
              className="flex items-center text-[#F50078] gap-2 px-2 py-1 border-[#F50078] border-2 rounded-lg uppercase text-sm font-bold leading-none hover:text-white hover:bg-[#F50078] transition-all duration-200"
              onClick={() => setShowHistory?.(!showHistory)}
            >
              History <ChevronsRight />
            </button>
            <div className="flex justify-end gap-6 items-center text-[#F50078]">
              {/* <Share isPublic={challengeIsPublic} id={id} /> */}
              <Tooltip text="New Session">
                <Link
                  href={`/app`}
                  className="flex items-center text-[#F50078] gap-2 px-1 py-1 border-[#F50078] border-2 rounded-full uppercase text-sm font-bold leading-none hover:text-white hover:bg-[#F50078] transition-all duration-200"
                >
                  <span className="sr-only">New</span>{" "}
                  <Plus className=" text-current" />
                </Link>
              </Tooltip>
              {/* <Tooltip text="More">
              <EllipsisVertical className="w-8 h-8" />
            </Tooltip> */}
            </div>
          </div>
          <nav
            className={`w-[400px] max-w-full left-0 fixed h-screen z-[52] top-0 transition-all duration-300 ease-in-out ${
              showHistory ? "translate-x-0" : "translate-x-[-100%]"
            }`}
          >
            <div className="bg-[#000] h-full px-8 pb-[300px] overflow-auto text-white">
              <header className="sticky bg-[#000] pt-16 pb-4 top-0 flex justify-between z-[11]">
                <h3 className="text-4xl uppercase text-white font-bold">History</h3>
                <div></div>
                <div className="flex items-center">
                  <button
                    onClick={() => location.reload()}
                    className="flex items-center text-white gap-2 px-2 py-1 border-pink border-2 rounded-lg uppercase text-sm font-bold hover:bg-pink hover:text-black transition-all duration-200"
                  >
                    New <Plus className="currentColor" />
                  </button>
                  <button
                    className="absolute top-4 right-0 w-max z-100 flex items-center text-white gap-2"
                    onClick={() => {
                      setShowHistory?.(!showHistory);
                    }}
                  >
                    <span className="sr-only">Close</span>{" "}
                    <ChevronsLeft className="text-pink" />
                  </button>
                </div>
              </header>
              <ChallengeGrid challenges={user?.challenges || []} />
            </div>
          </nav>
        </>
      )}
      {menuOpen && (
        <button
          onClick={() => setMenuOpen(false)}
          className="menu-button fixed top-[50px] right-6 z-[51] text-white animation-fade-in animation-delay"
        >
          <span className="sr-only">Close</span> <X className="w-12 h-12" />
        </button>
      )}
    </>
  );
};

export default SiteHeader;
